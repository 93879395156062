import request from '@/utils/request'
const baseURL = '/baseapi'
const RotationApi = {
  Condition: '/admin/rotation/condition',
  RotationList: '/admin/rotation/rotationList',
  RotationInfo: '/admin/rotation/rotationInfo',
  AddRotation: '/admin/rotation/addRotation',
  SetStatus: '/admin/rotation/setStatus',
  DelRotation: '/admin/rotation/delRotation'
}

export function rotationCondition () {
  return request({
    baseURL: baseURL,
    url: RotationApi.Condition,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function rotationList (parameter) {
  return request({
    baseURL: baseURL,
    url: RotationApi.RotationList,
    method: 'post',
    data: parameter
  })
}

export function rotationInfo (parameter) {
  return request({
    baseURL: baseURL,
    url: RotationApi.RotationInfo,
    method: 'post',
    data: parameter
  })
}

export function addRotation (parameter) {
  return request({
    baseURL: baseURL,
    url: RotationApi.AddRotation,
    method: 'post',
    data: parameter
  })
}

export function setRotationStatus (parameter) {
  return request({
    baseURL: baseURL,
    url: RotationApi.SetStatus,
    method: 'post',
    data: parameter
  })
}

export function delRotation (parameter) {
  return request({
    baseURL: baseURL,
    url: RotationApi.DelRotation,
    method: 'post',
    data: parameter
  })
}
