<template>
  <a-modal
    title="添加/编辑轮播图"
    :width="800"
    :destroyOnClose="true"
    v-model="visible"
    :confirmLoading="addloading"
    @ok="saveEventApi"
    @cancel="cancelForm"
  >
    <a-form :form="form">
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="标题"
        :validateStatus="checkParams.titleStatus"
        :help="checkParams.titleStatusMsg"
      >
        <a-input
          placeholder="请输入标题"
          v-decorator="['title', {initialValue: rotationData.title, rules: [{required: true, validator: titleCheck, validateTrigger: 'change'}]}]"
        />
      </a-form-item>
      <a-form-item
        style="margin-bottom: 0px;"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="图片"
        :validateStatus="checkParams.imageStatus"
        :help="checkParams.imageStatusMsg"
      >
        <a-upload
          :fileList="fileList"
          listType="picture-card"
          :max-count="1"
          :disabled="isImageUpload"
          :beforeUpload="beforeUpload"
          :customRequest="customRequest"
          @preview="handlePreview"
          :remove="removeImage"
          accept="image/*"
        >
          <div>
            <span><a-icon :type="uploading ? 'loading' : 'picture'" /></span>
            <span>上传</span>
          </div>
        </a-upload>
        <a-modal :visible="previewInfo.previewVisible" :title="previewInfo.previewTitle" :footer="null" @cancel="handleCancel">
          <img alt="example" style="width: 100%" :src="previewInfo.previewImage" />
        </a-modal>
      </a-form-item>
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="跳转链接"
      >
        <a-input
          placeholder="请输入跳转链接"
          v-model="rotationData.url"
        />
      </a-form-item>
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="位置"
        :validateStatus="checkParams.typeStatus"
        :help="checkParams.typeStatusMsg"
      >
        <a-select
          style="width:163px;"
          v-model="previewType"
          placeholder="请选择位置"
          @change="changeType"
        >
          <a-select-option :key="-1" :value="-1">请选择位置</a-select-option>
          <a-select-option v-for="item in typeList" :key="item.id" :value="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="时间范围"
      >
        <a-range-picker
          style="width: 80%"
          showTime
          :placeholder="['开始时间', '结束时间']"
          valueFormat="YYYY-MM-DD HH:mm:ss"
          format="YYYY-MM-DD HH:mm:ss"
          v-model="rotationTime"
        />
      </a-form-item>
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="状态"
      >
        <a-radio-group
          v-model="rotationData.status"
          style="width: 100%"
        >
          <a-radio :value="0">上架</a-radio>
          <a-radio :value="1">下架</a-radio>
        </a-radio-group>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>

import OSS from 'ali-oss'
import { addImagelibrary, getToken } from '@/api/upload'
import { addRotation } from '@/api/rotation'

export default {
  name: 'RotationInfo',
  props: {
    statusList: {
      type: Array,
      default () {
        return []
      }
    },
    typeList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      visible: false,
      previewType: -1,
      rotationStatus: 1,
      form: this.$form.createForm(this, { name: 'addRotationForm', preserve: false }),
      addloading: false,
      dealCheckStatus: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      loading: false,
      queryParam: {
        id: '',
        keyId: 0,
        status: -1,
        desc: '',
        imageList: []
      },
      previewInfo: {
        previewVisible: false,
        previewTitle: '',
        previewImage: ''
      },
      fileList: [],
      isImageUpload: false,
      uploading: false,
      uploadImageInfo: {
        fileName: '',
        url: '',
        width: 0,
        height: 0,
        size: 0,
        bucket: 'dadaojianqi-syue',
        type: 0,
        transcoding: 0
      },
      tokenInfo: {
        'region': '',
        'AccessKeyId': '',
        'AccessKeySecret': '',
        'SecurityToken': '',
        'bucket': '',
        'domain': ''
      },
      checkParams: {
        typeStatus: 'success',
        typeStatusMsg: null,
        titleStatus: 'success',
        titleStatusMsg: null,
        imageStatus: 'success',
        imageStatusMsg: null
      },
      rotationData: {},
      rotationTime: []
    }
  },
  created () {
  },
  methods: {
    initialData (data) {
      this.rotationData = Object.assign({}, data)
      this.checkParams.titleStatus = 'success'
      this.checkParams.titleStatusMsg = null
      this.checkParams.typeStatus = 'success'
      this.checkParams.typeStatusMsg = null
      this.checkParams.imageStatus = 'success'
      this.checkParams.imageStatusMsg = null
      this.previewType = this.rotationData.type
      this.rotationStatus = this.rotationData.status
      console.log('rotationData', this.rotationData)
      this.getTokenApi()
      this.fileList = this.rotationData.attachmentsList
      this.rotationTime = [this.rotationData.startTime, this.rotationData.endTime]
      this.visible = true
      console.log(this.fileList)
    },
    cancelForm () {
      this.rotationData = {}
      this.visible = false
    },
    handleCancel (e) {
      console.log(e)
      this.previewInfo.previewVisible = false
      this.previewInfo.previewImage = ''
      this.previewInfo.previewTitle = ''
    },
    saveEventApi () {
      const _this = this
      console.log('saveDealEvent', _this.rotationData)
      if (_this.rotationData.title.trim() === '') {
        _this.checkParams.titleStatus = 'error'
        _this.checkParams.titleStatusMsg = '请输入标题'
        return false
      } else {
        _this.checkParams.titleStatus = 'success'
        _this.checkParams.titleStatusMsg = null
      }

      if (_this.fileList.length <= 0) {
        _this.checkParams.imageStatus = 'error'
        _this.checkParams.imageStatusMsg = '请上传轮播图'
        return false
      } else {
        _this.checkParams.imageStatus = 'success'
        _this.checkParams.imageStatusMsg = null
      }

      if (_this.previewType === -1) {
        _this.checkParams.typeStatus = 'error'
        _this.checkParams.typeStatusMsg = '请选择位置'
        return false
      } else {
        _this.checkParams.typeStatus = 'success'
        _this.checkParams.typeStatusMsg = null
      }

      _this.addloading = true
      _this.rotationData.type = _this.previewType
      _this.rotationData.imageUrl = _this.fileList[0].url
      _this.rotationData.startTime = _this.rotationTime[0] ? _this.rotationTime[0] : ''
      _this.rotationData.endTime = _this.rotationTime[1] ? _this.rotationTime[1] : ''
      _this.rotationData.rotationId = _this.rotationData.id
      addRotation(_this.rotationData).then((res) => {
        if (res.errorCode === 0) {
          if (_this.rotationData.rotationId > 0) {
            _this.$message.success('编辑成功')
          } else {
            _this.$message.success('添加成功')
          }
          _this.addloading = false
          _this.visible = false
          _this.$emit('ok', 1)
        } else {
          _this.addloading = false
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        _this.addloading = false
        console.log(err)
      })
    },
    handlePreview (e) {
      this.previewInfo.previewImage = e.url
      this.previewInfo.previewTitle = e.name
      this.previewInfo.previewVisible = true
      console.log('handlePreview', e)
    },
    removeImage (e) {
      console.log('removeImage', e)
      this.fileList = []
      this.checkParams.imageStatus = 'error'
      this.checkParams.imageStatusMsg = '请上传轮播图'
      return true
    },
    getTokenApi () {
      const _this = this
      getToken().then((res) => {
        if (res.errorCode === 0) {
          _this.tokenInfo = res.result
          console.log('tokenInfo', _this.tokenInfo)
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        _this.$message.error('系统错误')
        console.log(err)
      })
    },
    addImagelibraryApi () {
      const _this = this
      addImagelibrary(_this.uploadImageInfo).then((res) => {
        if (res.errorCode === 0) {
          _this.fileList[0] = {
            uid: res.result.id,
            name: res.result.fileName,
            status: 'done',
            url: res.result.url
          }
          console.log('fileList', _this.fileList)
          _this.checkParams.imageStatus = 'success'
          _this.checkParams.imageStatusMsg = null
        } else {
          _this.$message.error(res.errorMsg)
        }
        _this.isImageUpload = false
        _this.uploading = false
      }).catch((err) => {
        _this.isImageUpload = false
        _this.uploading = false
        _this.$message.error('系统错误')
        console.log(err)
      })
    },
    async customRequest (e) {
      console.log('customRequest', e)
      const _this = this
      const client = new OSS({
        // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
        region: _this.tokenInfo.region,
        // 阿里云账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM用户进行API访问或日常运维，请登录RAM控制台创建RAM用户。
        accessKeyId: _this.tokenInfo.AccessKeyId,
        accessKeySecret: _this.tokenInfo.AccessKeySecret,
        stsToken: _this.tokenInfo.SecurityToken,
        // 填写Bucket名称，例如examplebucket。
        bucket: _this.tokenInfo.bucket,
        secure: true
      })

      const headers = {
        // 指定该Object被下载时的网页缓存行为。
        'Cache-Control': 'no-cache',
        // 指定该Object被下载时的名称。
        'Content-Disposition': _this.uploadImageInfo.fileName,
        // 指定该Object被下载时的内容编码格式。
        'Expires': 1000,
        'Content-Encoding': 'utf-8',
        // 指定Object标签，可同时设置多个标签。
        'x-oss-tagging': 'image=1',
        // 指定初始化分片上传时是否覆盖同名Object。此处设置为true，表示禁止覆盖同名Object。
        'x-oss-forbid-overwrite': 'true'
      }

      const options = {
        // 获取分片上传进度、断点和返回值。
        progress: (p, cpt, res) => {
          console.log(p)
        },
        // 设置并发上传的分片数量。
        parallel: 4,
        // 设置分片大小。默认值为1 MB，最小值为100 KB。
        partSize: 1024 * 1024,
        headers
      }
      console.log(client, options)

      try {
        const result = await client.multipartUpload(_this.uploadImageInfo.fileName, e.file, {
          ...options
        })

        if (result.name) {
          console.log(_this.tokenInfo.domain + result.name)
          _this.uploadImageInfo.url = _this.tokenInfo.domain + result.name
          _this.addImagelibraryApi()
        } else {
          _this.$message.error('上传失败')
          _this.isImageUpload = false
          _this.uploading = false
        }
        console.log(result)
      } catch (e) {
        _this.isImageUpload = false
        _this.uploading = false
        console.log(e)
      }
    },
    beforeUpload (file, fileList) {
      console.log('beforeUpload', file)
      if (this.isImageUpload) {
        this.$message.error('有其他图片正常上传，请稍后在操作')
        return false
      }
      console.log(file)
      var fileArr = file.name.split('.')
      var fileType = fileArr[fileArr.length - 1]

      var myDate = new Date()
      var year = myDate.getFullYear()
      var month = myDate.getMonth() + 1
      var day = myDate.getDate()
      if (month >= 1 && month <= 9) {
        month = '0' + month
      }

      if (day >= 0 && day <= 9) {
        day = '0' + day
      }

      var randomStr = this.getRandomStr(14)
      var keyimg = 'image/' + year + '/' + month + day + '/' + randomStr + '.' + fileType
      console.log(keyimg)
      this.uploadImageInfo.fileName = keyimg
      this.uploadImageInfo.size = Math.ceil(file.size / 1000)
      this.isImageUpload = true
      this.uploading = true
      return true
    },
    getRandomStr (len) {
      var str = ''
      for (var i = 0; i < len; i++) {
        str += Math.random().toString(36).substr(2)
      }
      return str.substr(0, len)
    },
    changeType (e) {
      console.log('changeType', e)
      this.rotationData.type = this.previewType
      if (e === -1 || e === undefined) {
        this.checkParams.typeStatus = 'error'
        this.checkParams.typeStatusMsg = '请选择位置'
      } else {
        this.checkParams.typeStatus = 'success'
        this.checkParams.typeStatusMsg = null
      }
    },
    titleCheck (rule, value, callback) {
      const _this = this
      _this.rotationData.title = value.trim()
      if (_this.rotationData.title !== '') {
        _this.checkParams.titleStatus = 'success'
        _this.checkParams.titleStatusMsg = null
      } else {
        _this.checkParams.titleStatus = 'error'
        _this.checkParams.titleStatusMsg = '请输入标题'
        callback(new Error('请输入标题'))
      }
    }
  }
}
</script>

<style lang="less" scoped>
</style>
